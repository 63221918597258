import React, { useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Typography,
    Switch,
    FormControlLabel
} from '@mui/material';

//@TODO make sure webcam isn't being used if it's not required - chrome says its still active when is disabled


interface WebcamPreviewProps {
    isMobile: boolean;
    isWebcamRequired: boolean;
    open: boolean;
    onClose: () => void;
    onDevicesSelected: (videoDeviceId: string, audioDeviceId: string) => void;
}

const WebcamPreviewModal: React.FC<WebcamPreviewProps> = ({
    isMobile,
    isWebcamRequired,
    open,
    onClose,
    onDevicesSelected
}) => {
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<string>('');
    const [selectedAudio, setSelectedAudio] = useState<string>('');
    const [useWebcam, setUseWebcam] = useState<boolean>(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const analyzerRef = useRef<AnalyserNode | null>(null);
    const animationFrameRef = useRef<number>();

    useEffect(() => {
        if (open && useWebcam) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    const videos = devices.filter(device => device.kind === 'videoinput');
                    const audios = devices.filter(device => device.kind === 'audioinput');
                    setVideoDevices(videos);
                    setAudioDevices(audios);

                    if (videos.length) setSelectedVideo(videos[0].deviceId);
                    if (audios.length) setSelectedAudio(audios[0].deviceId);
                })
                .catch(err => console.error('Error enumerating devices:', err));
        }
    }, [open, useWebcam]);

    useEffect(() => {
        if (selectedVideo && videoRef.current && useWebcam) {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }

            navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideo },
                audio: false
            })
                .then(stream => {
                    streamRef.current = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                })
                .catch(err => console.error('Error accessing media devices:', err));
        }

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, [selectedVideo, useWebcam]);

    useEffect(() => {
        if (selectedAudio) {
            navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedAudio }
            })
                .then(stream => {
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream);
                    const analyzer = audioContext.createAnalyser();
                    analyzer.fftSize = 512;
                    analyzer.smoothingTimeConstant = 0.8;
                    source.connect(analyzer);
                    analyzerRef.current = analyzer;

                    const drawAudioLevel = () => {
                        if (!canvasRef.current || !analyzer) return;

                        const canvas = canvasRef.current;
                        const ctx = canvas.getContext('2d');
                        if (!ctx) return;

                        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
                        analyzer.getByteFrequencyData(dataArray);

                        // Clear canvas with a slight fade effect
                        ctx.fillStyle = 'rgba(245, 245, 245, 0.2)';
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        // Set up visualization parameters
                        const centerY = canvas.height / 2;
                        const barWidth = 4;
                        const barSpacing = 2;
                        const maxBarHeight = canvas.height * 0.6;
                        const bars = Math.min(dataArray.length, Math.floor(canvas.width / (barWidth + barSpacing)));
                        const startX = (canvas.width - (bars * (barWidth + barSpacing))) / 2;

                        // Draw each bar
                        for (let i = 0; i < bars; i++) {
                            const value = dataArray[i];
                            const barHeight = (value / 255) * maxBarHeight;
                            const x = startX + i * (barWidth + barSpacing);

                            // Create gradient for each bar
                            const gradient = ctx.createLinearGradient(0, centerY - barHeight / 2, 0, centerY + barHeight / 2);
                            //gradient.addColorStop(0, '#F4E132');
                            gradient.addColorStop(0, '#000000');
                            gradient.addColorStop(1, '#000000');

                            // Draw bar with rounded corners
                            ctx.beginPath();
                            ctx.roundRect(
                                x,
                                centerY - barHeight / 2,
                                barWidth,
                                barHeight,
                                2
                            );
                            ctx.fillStyle = gradient;
                            ctx.fill();
                        }

                        animationFrameRef.current = requestAnimationFrame(drawAudioLevel);
                    };

                    drawAudioLevel();
                })
                .catch(err => console.error('Error accessing microphone:', err));
        }

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [selectedAudio]);

    useEffect(() => {
        if (!useWebcam && streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
    }, [useWebcam]);

    const handleConfirm = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
        onDevicesSelected(useWebcam ? selectedVideo : '', selectedAudio);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            maxWidth="lg"
            fullWidth
            fullScreen={isMobile}
            PaperProps={{
                sx: {
                    borderRadius: { xs: 0, md: '4px' },
                    overflow: 'hidden',
                    height: { xs: '100vh', md: '88vh' },
                    maxHeight: { xs: '100vh', md: '88vh' }
                }
            }}
        >
            <DialogTitle sx={{
                fontFamily: 'PPNeueMontreal',
                fontWeight: 600,
                fontSize: { xs: '20px', md: '24px' },
                padding: { xs: '12px 16px', md: '16px 24px' },
                marginBottom: { xs: '16px', md: '24px' },
                borderBottom: '1px solid #E9E9E9',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <span>Camera & Microphone Setup</span>
                {!isWebcamRequired && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useWebcam}
                                onChange={(e) => setUseWebcam(e.target.checked)}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#F4E132',
                                        '&:hover': {
                                            backgroundColor: 'rgba(244, 225, 50, 0.04)',
                                        },
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#F4E132',
                                    },
                                }}
                            />
                        }
                        label="Enable webcam"
                        sx={{ m: 0 }}
                    />
                )}
            </DialogTitle>
            <DialogContent sx={{ padding: '24px' }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    {useWebcam && (
                        <Box sx={{
                            flex: 1,
                            minWidth: { xs: '100%', md: '45%' }
                        }}>
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    backgroundColor: '#f5f5f5',
                                    marginBottom: '16px'
                                }}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel sx={{
                                    color: '#000',
                                    '&.Mui-focused': { color: '#000' }
                                }}>Camera</InputLabel>
                                <Select
                                    value={selectedVideo}
                                    onChange={(e) => setSelectedVideo(e.target.value)}
                                    label="Camera"
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                        '& .Mui-selected': { color: '#666666' }
                                    }}
                                >
                                    {videoDevices.map(device => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label || `Camera ${device.deviceId.slice(0, 5)}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box sx={{
                        flex: 1,
                        minWidth: { xs: '100%', md: useWebcam ? '45%' : '100%' }
                    }}>
                        <canvas
                            ref={canvasRef}
                            style={{
                                width: '100%',
                                height: '400px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                                marginBottom: '16px'
                            }}
                            width={300}
                            height={300}
                        />
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel sx={{
                                color: '#000',
                                '&.Mui-focused': { color: '#000' }
                            }}>Microphone</InputLabel>
                            <Select
                                value={selectedAudio}
                                onChange={(e) => setSelectedAudio(e.target.value)}
                                label="Microphone"
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                    '& .Mui-selected': { color: '#666666' }
                                }}
                            >
                                {audioDevices.map(device => (
                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                        {device.label || `Microphone ${device.deviceId.slice(0, 5)}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <IconButton
                    onClick={handleConfirm}
                    style={{
                        background: '#000',
                        color: 'white',
                        borderRadius: 10,
                        fontFamily: 'PPNeueMontreal',
                        paddingLeft: 20,
                        paddingRight: 20,
                        fontSize: 16
                    }}
                    autoFocus
                >
                    Confirm
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default WebcamPreviewModal;
