import React from 'react';

interface VideoFeedProps {
    videoStream: MediaStream | null;
}

const VideoFeed = React.memo(({ videoStream }: VideoFeedProps) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
        if (videoRef.current && videoStream) {
            videoRef.current.srcObject = videoStream;
        }
    }, [videoStream]);

    if (!videoStream) return null;

    return (
        <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={{
                width: '80%',
                height: '100%',
                borderRadius: '8px',
                border: '1px solid #E9E9E9',
                backgroundColor: '#F8F8F8',
                objectFit: 'cover'
            }}
        />
    );
});

export default VideoFeed; 