import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Phone from './Phone';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
// import posthog from 'posthog-js'
// posthog.init('phc_WcTIhKTBtZfgo4URTcSni32bkl8oACPOtBZ5t6sYmW9', { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' })

Sentry.init({
  dsn: "https://6491e8f8a842383b07b87682b2c9d2ab@o4507392418775040.ingest.us.sentry.io/4507392495058944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Override Material UI Theme Font
const customTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/phone',
    element: <Phone />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
